



.cooperations {
    ul {
        li {
            margin-bottom: 3rem;
            img {
                display: inline;
                margin: 0;
            }
        }
    }
  }
  
.carousel__holder {
    width: 100%; 
    position: relative; 
    padding-bottom: 50%; 
    margin: 1rem 0 1rem;

    .carousel {
        height: 100%;
        width: 100%;
        overflow: hidden;
        text-align: center;
        position: absolute;
        padding: 0;

        .carousel__controls,
        .carousel__activator {
            display: none;
        }

        .carousel__control {
            height: 30px;
            width: 30px;
            margin-top: -15px;
            top: 50%;
            position: absolute;
            display: block;
            cursor: pointer;
            border-width: 5px 5px 0 0;
            border-style: solid;
            border-color: #222;
            opacity: 0.35;
            opacity: 1;
            outline: 0;
            z-index: 3;

            &:hover {
              opacity: 1;
            }
          }

        
            .carousel__activator:nth-of-type(1):checked ~ .carousel__track {
                -webkit-transform: translateX(-000%);
                transform: translateX(-000%);
            }

            .carousel__activator:nth-of-type(1):checked ~ .carousel__slide:nth-of-type(1) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(1):checked ~ .carousel__controls:nth-of-type(1) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(1):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(1) {
                opacity: 1;
            }
        
            .carousel__activator:nth-of-type(2):checked ~ .carousel__track {
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }

            .carousel__activator:nth-of-type(2):checked ~ .carousel__slide:nth-of-type(2) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(2):checked ~ .carousel__controls:nth-of-type(2) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(2):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(2) {
                opacity: 1;
            }
        
            .carousel__activator:nth-of-type(3):checked ~ .carousel__track {
                -webkit-transform: translateX(-200%);
                transform: translateX(-200%);
            }

            .carousel__activator:nth-of-type(3):checked ~ .carousel__slide:nth-of-type(3) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(3):checked ~ .carousel__controls:nth-of-type(3) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(3):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(3) {
                opacity: 1;
            }
        
            .carousel__activator:nth-of-type(4):checked ~ .carousel__track {
                -webkit-transform: translateX(-300%);
                transform: translateX(-300%);
            }

            .carousel__activator:nth-of-type(4):checked ~ .carousel__slide:nth-of-type(4) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(4):checked ~ .carousel__controls:nth-of-type(4) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(4):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(4) {
                opacity: 1;
            }
        
            .carousel__activator:nth-of-type(5):checked ~ .carousel__track {
                -webkit-transform: translateX(-400%);
                transform: translateX(-400%);
            }

            .carousel__activator:nth-of-type(5):checked ~ .carousel__slide:nth-of-type(5) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(5):checked ~ .carousel__controls:nth-of-type(5) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(5):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(5) {
                opacity: 1;
            }
        
            .carousel__activator:nth-of-type(6):checked ~ .carousel__track {
                -webkit-transform: translateX(-500%);
                transform: translateX(-500%);
            }

            .carousel__activator:nth-of-type(6):checked ~ .carousel__slide:nth-of-type(6) {
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
            }

            .carousel__activator:nth-of-type(6):checked ~ .carousel__controls:nth-of-type(6) {
                display: block;
                opacity: 1;
            }

            .carousel__activator:nth-of-type(6):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(6) {
                opacity: 1;
            }
        
        .carousel__control--backward {
            left: 20px;
            -webkit-transform: rotate(-135deg);
                    transform: rotate(-135deg);
          }
          .carousel__control--forward {
            right: 20px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
          }
          .carousel__indicators {
            position: absolute;
            bottom: 20px;
            width: 100%;
            text-align: center;
          }
          .carousel__indicator {
            height: 15px;
            width: 15px;
            border-radius: 100%;
            display: inline-block;
            z-index: 2;
            cursor: pointer;
            opacity: 0.35;
            margin: 0 2.5px 0 2.5px;
          }
          .carousel__indicator:hover {
            opacity: 0.75;
          }
          .carousel__track {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0;
            margin: 0;
            transition: -webkit-transform 0.5s ease 0s;
            transition: transform 0.5s ease 0s;
            transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
          }
          .carousel__track .carousel__slide {
            display: block;
            top: 0;
            left: 0;
            right: 0;
            opacity: 1;
            text-align: center;
            img {
                margin: auto;
            }
          }
          
          .carousel__track .carousel__slide:nth-of-type(1) {
            -webkit-transform: translateX(000%);
                    transform: translateX(000%);
          }
          
          .carousel__track .carousel__slide:nth-of-type(2) {
            -webkit-transform: translateX(100%);
                    transform: translateX(100%);
          }
          
          .carousel__track .carousel__slide:nth-of-type(3) {
            -webkit-transform: translateX(200%);
                    transform: translateX(200%);
          }
          
          .carousel__track .carousel__slide:nth-of-type(4) {
            -webkit-transform: translateX(300%);
                    transform: translateX(300%);
          }
          
          .carousel__track .carousel__slide:nth-of-type(5) {
            -webkit-transform: translateX(400%);
                    transform: translateX(400%);
          }
          
          .carousel__track .carousel__slide:nth-of-type(6) {
            -webkit-transform: translateX(500%);
                    transform: translateX(500%);
          }
          
          
          .carousel--scale .carousel__slide {
            -webkit-transform: scale(0);
                    transform: scale(0);
          }
          .carousel__slide {
            height: 100%;
            position: absolute;
            opacity: 0;
            overflow: hidden;
          }
          .carousel__slide .overlay {height: 100%;}
          .carousel--thumb .carousel__indicator {
            height: 30px;
            width: 30px;
          }
          .carousel__indicator {
            background-color: #fafafa;
          }
          
          .carousel__slide:nth-of-type(1),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(1) {
            background-size: cover;
            background-position: center;
          }
          
          .carousel__slide:nth-of-type(2),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(2) {
            background-size: cover;
            background-position: center;
          }
          
          .carousel__slide:nth-of-type(3),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(3) {
            background-size: cover;
            background-position: center;
          }
          
          .carousel__slide:nth-of-type(4),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(4) {
            background-size: cover;
            background-position: center;
          }
          
          .carousel__slide:nth-of-type(5),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(5) {
            background-size: cover;
            background-position: center;
          }
          
          .carousel__slide:nth-of-type(6),
          .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(6) {
            background-size: cover;
            background-position: center;
          }
          
    }

}
